import React, { useState } from 'react';
// 3rd Party
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import AnimateHeight from 'react-animate-height';
import { v4 } from 'uuid';
// Styles
import './BulletEntry.scss';

const BulletEntry = (props) => {
  const [height, setHeight] = useState('auto');
  const dropdownAnimationTime = 500; // ms

  const toggle = () => {
    setHeight(height ? 0 : 'auto');
  }

  return (
    <Row className="BulletEntry">
      <Col md={{ span: 10, offset: 1 }}>
        <h2 onClick={toggle} className="bullet-entry-title">
          <FontAwesomeIcon
            className={height ? 'fa-rotate-90' : ''}
            icon={faCaretRight}
            size='2x'
            style={{ transition: `all ${dropdownAnimationTime}ms ease-out` }}
          />
          {props.title}
        </h2>
        <AnimateHeight
          duration={ dropdownAnimationTime }
          height={ height } >
          {
            props.contentArr
            .map((contentStr) => (
              <p
                key={v4()}
                className="lead info" >
                {contentStr}
              </p>
            ))
          }
        </AnimateHeight>
      </Col>
    </Row>
  );
}

export default BulletEntry;
