import React from 'react';
// 3rd Party
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { v4 } from 'uuid';
import scrollToComponent from 'react-scroll-to-component';
// Components
import BulletEntry from './presentationComponents/BulletEntry';
import IconBlock from './presentationComponents/IconBlock';
import {
  bulletsAndDropdowns,
  iconsArr,
  bulletPoints,
  conclusion
} from './data';
import logo from './mars-future-cat.png';
// Styles
import './App.scss';

const App = () =>  {
  let BulletEntrySec;

  const scrollTo = () => {
    scrollToComponent(
      BulletEntrySec,
      {
        offset: 0,
        align: 'top',
        duration: 2000
      }
    );
  }

  return (
    <div className="App">
      <div className="title-container">

        <img
          src={logo}
          alt="Futurist Cat"
          className="futurist-cat-img" />
        <h1 className="text-site-title">
          Fungible Universal Currency Token<br />🪙 FUCT
        </h1>
      </div>
      <header className="App-header first">
        <FontAwesomeIcon
          icon={faChevronDown}
          size='5x'
          onClick={scrollTo}
        />
      </header>
      <Container
        className="grid-container"
        ref={(sec) => { BulletEntrySec = sec; }}>
        {
          bulletsAndDropdowns
          .map((entry) => (
            <BulletEntry
              key={v4()}
              title={entry.title}
              contentArr={entry.content}
            />
          ))
        }
        <Row className="icon-row">
          {
            iconsArr
            .map((obj)=>(
              <IconBlock
                key={v4()}
                icon={obj.icon}
                description={obj.description} />
            ))
          }
        </Row>
        <Row className="splash-block bottom-block">
          <Col sm={{ span: 10, offset: 1 }}>
            <h2>
              Is this a joke? You bet your litterbox!
            </h2>
            <p className="lead">You want a coin that's:</p>
            <ul>
              {
                bulletPoints
                .map((reasonStr) => (
                  <li key={v4()} className="lead">
                    {reasonStr}
                  </li>
                ))
              }
            </ul>
            <p className="lead">If you want all those things, you want FUCT coin!</p>
          </Col>
        </Row>
        <Row className="final-block bottom-block">
          <Col sm={{ span: 10, offset: 1 }}>
            <h2>🪙 FUCT Coin's Plan</h2>
            {
              conclusion
              .map((sentence) => (
                <p key={v4()} className="lead">
                  {sentence}
                </p>
              ))
            }
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
