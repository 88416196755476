import {
  faCode,
  faCat,
  faChartLine } from '@fortawesome/free-solid-svg-icons';

const bulletsAndDropdowns = [
  {
    title: 'This token addresses the lack of cat-themed tokens. 😿',
    content: [
      'With the recent explosion of dog themed coins (like Shiba Inu, Baby Doge, etc.), cats are being left out in the cold. Because this is the internet, we need cute cats!',
      'Adorable cats are the bedrock of the internet! We need more cat coins. 🐈'
    ]
  },
  {
    title: 'An ERC20 token for space cats, like Futurist Cat! 🚀',
    content: [
      'Our mascot above is Futurist Cat. FUCT Cat bravey goes where others fear to tread!'
    ]
  },
  {
    title: 'FUCT: It\'s not f*cked! 👍',
    content: [
      'It\'s an acutal ERC 20 token, that can be traded on Uniswap or wherever tokens are traded.'
    ]
  }
];

const iconsArr = [
  {
    icon: faCode,
    description: ['Hand-coded!']
  },
  {
    icon: faCat,
    description: ['😻😻😻😻']
  },
  {
    icon: faChartLine,
    description: ['We like graphs that to up & to the right!']
  }
];

const bulletPoints = [
  'fun,',
  'will build a decentralized community,',
  'and be widely wanted.'
];
const conclusion = [
  '✅ Create ERC20 token with a cool cat logo.',
  '• Be honest about the meme-coin/shit-coin nature of this project. 😎',
  '• To the moon! 🚀🔥🎊🚀🔥🎊🚀🔥🎊🚀🔥🎊🚀🔥🎊',
];

export {
  bulletsAndDropdowns,
  iconsArr,
  bulletPoints,
  conclusion
};