import React from 'react';
// 3rd Party
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 } from 'uuid';
// Styles
import './IconBlock.scss';

const IconBlock = (props) => {
  const { icon, description } = props;

  return (
    <Col md={{ span: 4 }} className="IconBlock">
      <p className="lead">
        <FontAwesomeIcon
          icon={icon}
          size='8x'
        />
      </p>
      {
        description
        .map((descriptionStr) => (
          <p key={v4()} className="lead description">
            {descriptionStr}
          </p>
        ))
      }
    </Col>
  );
}

export default IconBlock;
